// 获取用户/作者 个人信息
export const GETUSERCENTERAUTHORINFO = 'get-user-center-author-info'
// 获取用户/作者 个人成就
export const GETUSERCENTERAUTHORACHIEVEMENT = 'get-user-center-author-achievement'
// 获取用户/作者 文章列表
export const GETUSERCENTERAUTHORSHOWCOLUMNLIST = 'get-user-center-author-showcolumn-list'
// 获取用户/作者 视频列表
export const GETUSERCENTERAUTHORVIDEOLIST = 'get-user-center-author-video-list'
// 获取用户/作者 收藏列表
export const GETUSERCENTERAUTHORCOLLECTLIST = 'get-user-center-author-collect-list'
// 获取用户/作者 关注列表
export const GETUSERCENTERAUTHORFOLLOWLIST = 'get-user-center-author-follow-list'
