// 获取首页 7x24H 快讯
export const GETSHOWLIVESLIST = 'get-show-lives-list'
// 获取首页项目动态(潜伏日历)
export const GETCALENDARLIST = 'get-calendar-list'
// 获取首页推荐直播列表
export const GETHOOMROOMLIVERECOMMENDLIST = 'get-hoom-room-live-recommend-list'
// 获取首页直播分类列表
export const GETHOOMROOMLIVETYPELISTOBJDATA = 'get-hoom-room-live-type-list-objdata'
// 获取首页直播列表
export const GETHOOMROOMLIVELIST = 'get-hoom-room-live-list'
// 是否显示实名验证弹窗
export const HOMELIVEVERIFYPOPUPSHOW = 'home-live-verify-popup-show'
