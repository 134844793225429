// action错误处理
export const ACTIONERROR = 'action-error'

// 获取友情链接
export const GETFRIENDLYLINKS = 'get-friendly-links'

// 获取合作伙伴
export const GETPARTNERLINKS = 'get-partner-links'

// 获取广告
export const GETAD = 'get-ad'

// 获取嵌入广告
export const GETADIMPLANT = 'get-ad-implant'

// 统计事件上报
export const GATHERPUSH = 'gather-push'
