// 获取新闻评论
export const GETNEWSCOMMENT = 'get-news-commnet'

// 获取新闻更多评论
export const GETNEWSMORECOMMENT = 'get-news-more-commnet'

// 回复评论
export const REPLYCOMENT = 'reply-comment'

// 删除评论
export const DELCOMMENT = 'del-comment'

// 删除回复
export const DELREPLY = 'del-reply'
