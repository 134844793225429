import zh from './zh-cn.json'
import tw from './zh-tw.json'

export default {
    'zh-cn': {
        translation: zh
    },
    'zh-tw': {
        translation: tw
    }
}
